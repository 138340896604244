<template>
  <base-section id="about-our-product">
    <base-section-heading color="grey lighten-2" title="Servicios" />

    <v-container>
      <v-row>
        <v-col v-for="card in cards" :key="card.title" cols="12" md="4">
          <base-info-card v-bind="card" />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
export default {
  name: "SectionAboutOurProduct",
  data: () => ({
    cards: [
      {
        title: "Cambio de grifos",
        // subtitle: 'Subtitulo',
        text:
          "La grifería cumple un papel fundamental en el confort diario del hogar. Un grifo en mal estado puede sufrir fugas o goteos y requiere ser renovado cada cierto tiempo.",
        callout: "1"
      },
      {
        title: "Arreglo de cisternas",
        // subtitle: 'Subtitulo',
        text:
          "Las fugas de agua son averías comunes en los baños. El primer indicio de que esta pieza está dañada es que hace ruido constante, y esto ocurre porque: el agua no deja de caer en el váter, el depósito no se llena, o no es posible tirar de la cadena.",
        callout: "2"
      },
      {
        title: "Lavabos",
        // subtitle: 'Subtitulo',
        text:
          "Los lavabos han evolucionado en los últimos años no sólo respecto del diseño, sino en la forma y mecanismos para su montaje.",
        callout: "3"
      },
      {
        title: "Calentadores",
        // subtitle: 'Subtitulo',
        text:
          "Son unidades muy pequeñas instaladas a poca distancia del lugar donde se requiere el agua caliente. Son alimentados con electricidad y se activan automáticamente por flujo o manualmente con un interruptor. Su uso se reduce a unas pocas aplicaciones comerciales o domésticas",
        callout: "4"
      },
      {
        title: "Desatascos de tuberías y desagüe",
        // subtitle: 'Subtitulo',
        text:
          "En este tipo de atascos la incidencia normalmente se produce en la parte del sifón, este es el responsable de evitar los malos olores y de no dejar pasar ninguna materia solida a las cañerías.",
        callout: "5"
      },
      {
        title: "Fugas",
        // subtitle: 'Subtitulo',
        text:
          "El agua puede causar problemas graves en una casa, desde arruinar paredes, techos y suelos, hasta averiar electrodomesticos. Así que, actuar con rapidez, cuando se detecta que algo anda mal, es prioritario.",
        callout: "6"
      },
      {
        title: "Ruptura de tubería",
        // subtitle: 'Subtitulo',
        text:
          "Una ruptura de una tubería puede causar una cantidad inmensa de daños a una propiedad. Puede que una ruptura ocurra debido a el daño de una tubería de agua, tubos congelados, la corrosión, tubos viejos, la baja calidad de tubos, demasiada presión en los tubos, una grieta a largo plazo, entre otras.",
        callout: "7"
      },
      {
        title: "Humedades y calderas",
        // subtitle: 'Subtitulo',
        text:
          "Especialmente en la temporada de calefacción, el moho se convierte rápidamente en un problema. Un factor básico para el desarrollo de moho es la humedad, que no se evacúa suficientemente hacia el exterior, especialmente en los meses fríos del año.",
        callout: "8"
      }
    ]
  })
};
</script>
